import './App.css';
import './index.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css'; // Import stylesheets at the top
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Empresa from './pages/Empresa';
import Produtos from './pages/Produtos';
import Servicos from './pages/Servicos';
import Contato from './pages/Contato';
function App() {
  


  return (
  <>
    
    <link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
  integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
  crossorigin="anonymous"
/>
<link href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;700&display=swap" rel="stylesheet"/>
  <link rel="stylesheet" href="index.css"/>
  <link rel="stylesheet" href="Footer.css"/>
  <link rel="stylesheet" href="Header.css"/>


<Router>
     

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/empresa" element={<Empresa />} />
        <Route path="/produtos" element={<Produtos />} />
        <Route path="/servicos" element={<Servicos />} />
        <Route path="/contato" element={<Contato />} />
      </Routes>
    </Router>
    
      
    </>
  );
}

export default App;
