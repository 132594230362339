import React from 'react';
import Header from '../header';
import Footer from '../footer';

function Contato() {
    let reso = window.innerWidth;
    function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
      
    function handleScroll() {
      var element = document.getElementById('animated-element');
      if (isElementInViewport(element)) {
        element.classList.add('fadeInScale');
      } else {
        element.classList.remove('fadeInScale');
      }
    }
    
    
    window.addEventListener('scroll', handleScroll);
   
  
    return <div> <Header />
    <h1 className='texto'>Transforme seus<br></br> processos e leve sua<br></br> empresa ao próximo <br></br>nível!!!</h1><br></br>
    <h3 className='texto2'>Potencialize sua produção, reduza despesas e impulsione seus lucros com nossas<br></br> soluções de painéis elétricos e automação </h3>
    {reso <= 480? 
        <div className='backAllPages'>
        <div class="container custom-container-twoo">
           
              <div class="col-md-1 custom-row-frame-two-AllPages">
                <div class="box custom-title" id="animated-element"><h6>+15 anos<br></br>de experiência<br></br></h6>
                Com uma vasta trajetória na automação industrial e montagem de painéis elétricos, nossa empre​sa destaca-se pela expertise consolidada, garantindo soluções eficientes e inovadoras para seus projetos.</div>
              </div>
              <div class="col-md-1 custom-row-frame-two-AllPages custom-div-frame-two2 div1-frame-conteudo">
                <div class="box"></div>
              </div>
              
            </div>
           
          </div>
    
  
        :<div className='backAllPages'>
        <div class="container custom-container-two">
            <div class="row">
              <div class="col-md-4 custom-row-frame-two-AllPages custom-div-frame-two2">
                <div class="box custom-title" id="animated-element"><h1>+15 anos<br></br>de experiência</h1><br></br>
                <h3>Com uma vasta trajetória na automação industrial e montagem de painéis elétricos, nossa empre​sa destaca-se pela expertise consolidada, garantindo soluções eficientes e inovadoras para seus projetos.</h3></div>
              </div>
              <div class="col-md-4 custom-row-frame-two-AllPages custom-div-frame-two div1-frame-conteudo">
                <div class="box"></div>
              </div>
              
            </div>
           
          </div>
        </div>}
  
    <Footer/>
          </div>;
}

export default Contato;